import React from 'react'
import ReactDOM from 'react-dom'

//Apollo Client
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'

// React Router-Dom
import { BrowserRouter as Router } from 'react-router-dom'

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faList,
  faBookOpen,
  faTh,
  faGripVertical,
} from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import './styles/tailwind.css'

// Components
import App from './App'

library.add(faBookOpen, faList, faBell, faTh, faGripVertical)

const httpLink = createHttpLink({
  uri: 'https://nuggt-graphql-server.herokuapp.com',
  credentials: 'same-origin',
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
)
