import { gql } from 'apollo-boost'

const DASHBOARD_FEED_QUERY = gql`
    query getDashobardInfo {
        dashboardFeed {
            id
            email
            firstName
            lastName
            avatar
            role
            isLoggedIn
            createdAt
            ...userBio
            ...userAccountSettings
        }
    }

    fragment userBio on User {
        bio{
            id
            ethnicity
            gender
            title
            industry
            location
            title
            experiance
            body
        }
    }

    fragment userAccountSettings on User {
        accountSettings{
            id
            scheduleId
            memberId
            meetingId
        }
    }

`

const GET_CURRENT_USER_QUERY = gql`
    query getMyInfo {
        me {
            avatar
        }
    }
`

export {
    DASHBOARD_FEED_QUERY,
    GET_CURRENT_USER_QUERY
}
