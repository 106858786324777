import React, { useState, useEffect } from 'react'
import { Form, Field, Formik } from 'formik'
import { useQuery } from '@apollo/react-hooks'
import { useMutation } from '@apollo/react-hooks'
import { Switch as MaterialSwitch } from '@material-ui/core'
//Query/Mutation
import { DASHBOARD_FEED_QUERY } from '../../queries/queries'
import {
  DASHBOARD_UPDATE_ACCOUNT_SETTINGS,
  DASHBOARD_CHANGE_USER_ROLE,
} from '../../mutations/mutations'
//Components
import LoadingMessage from '../../utils/LoadingMessage'
import ErrorMessage from '../../utils/ErrorMessage'

const UserAccountSettings = ({ profileId }) => {
  const { loading, error, data, refetch } = useQuery(DASHBOARD_FEED_QUERY, {
    fetchPolicy: 'network-only',
  })
  const [changeUserRole] = useMutation(DASHBOARD_CHANGE_USER_ROLE)
  const [updateAccountSettings] = useMutation(DASHBOARD_UPDATE_ACCOUNT_SETTINGS)
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    refetch()
  })

  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />

  const userinfo = data.dashboardFeed.find((user) => user.id === profileId)

  const handleToggle = () => {
    // change pro status on toggle
    if (!toggle) {
      setToggle(true)
      changeUserRole({
        variables: {
          id: userinfo.id,
          role: 'PRO',
        },
      }).catch((e) => {
        return null
      })
    } else {
      setToggle(false)
      changeUserRole({
        variables: {
          id: userinfo.id,
          role: 'USER',
        },
      }).catch((e) => {
        return null
      })
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          scheduleId: userinfo.accountSettings.scheduleId, // userinfo.bio.industry,
          memberId: userinfo.accountSettings.memberId, //userinfo.bio.gender,
          meetingId: userinfo.accountSettings.meetingId, // userinfo.bio.title,
          userRole: userinfo.role === 'USER' ? false : true,
        }}
        onSubmit={(values, { setSubtting }) => {
          updateAccountSettings({
            variables: {
              id: userinfo.accountSettings.id,
              scheduleId: values.scheduleId,
              memberId: values.memberId,
              meetingId: values.meetingId,
            },
          }).catch((e) => {
            return null
          })
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Pro Status: {values.userRole ? 'ON' : 'OFF'}
              </label>
              <MaterialSwitch
                onChange={handleToggle}
                checked={values.userRole}
                value="true"
                name="userRole"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Acuity Calendar Account Id:
              </label>
              <Field
                className="shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                value={values.scheduleId}
                type="text"
                name="scheduleId"
                placeholder="Enter an Acuity Calendar Id"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Zoom Member Id:
              </label>
              <Field
                className="shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                value={values.memberId}
                type="text"
                name="memberId"
                placeholder="Enter a Zoom Member Id"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Zoom Meeting Id:
              </label>
              <Field
                className="shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                value={values.meetingId}
                type="text"
                name="meetingId"
                placeholder="Enter a Zoom Meeting Id"
              />
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UserAccountSettings
