import { gql } from 'apollo-boost'

const LOGIN_USER = gql`
    mutation dashboardLogin($email: String!, $password: String!, $isLoggedIn: Boolean!) {
        login(
            data:{
                email: $email
                password: $password
                isLoggedIn: $isLoggedIn
            }
        ) {
    token
  }
}`

const UPDATE_BIO = gql`
    mutation userBioUpdate($gender: Gender, $title: String, $ethnicity: Ethnicity, $location: String, $industry: Industry, $body: String, $experiance: Int) {
        updateBio(
            data: {
                gender: $gender
                title: $title
                ethnicity: $ethnicity
                location: $location
                industry: $industry
                experiance: $experiance
                body: $body
            }
        ) {
            id
    }
}

`

const DASHBOARD_UPDATE_BIO = gql`
    mutation dashboardUserBioUpdate($id: ID!, $gender: Gender, $title: String, $ethnicity: Ethnicity, $location: String, $industry: Industry, $body: String, $experiance: Int) {
        dashboardUpdateBio(
            id: $id,
            data: {
                gender: $gender
                title: $title
                ethnicity: $ethnicity
                location: $location
                industry: $industry
                experiance: $experiance
                body: $body
            }
        ) {
            id
    }
}`

const DASHBOARD_CHANGE_USER_ROLE = gql`
    mutation dashboardChangeUserRole($id: ID!, $role: Role!) {
        updateUserRole(
            uid: $id,
            data: $role
        ) {
            role
          }
}
`

const DASHBOARD_UPDATE_ACCOUNT_SETTINGS = gql`
    mutation dashboardUpdateAccountSettings($id: ID!, $scheduleId: String, $memberId: String, $meetingId: String) {
        updateAccountSettings(
            id: $id,
            data: {
                scheduleId: $scheduleId
                memberId: $memberId
                meetingId: $meetingId
            }
        ) {
            id
          }
}
`

export {
    LOGIN_USER,
    UPDATE_BIO,
    DASHBOARD_UPDATE_BIO,
    DASHBOARD_UPDATE_ACCOUNT_SETTINGS,
    DASHBOARD_CHANGE_USER_ROLE
}
