import React from 'react'
import dotenv from 'dotenv'

//components
import Dashboard from '../src/containers/Dashboard'
import Login from '../src/components/Login'
// React Router Dom
import { Route, Switch, Redirect } from 'react-router-dom'

import PrivateRoute from './utils/PrivateRoute'

dotenv.config()

const App = () => {
  return (
    <Switch>
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <Route exact path="/" component={Login} />
      <Route exact path="/error" render={() => <Redirect to="/" />} />
      <Route
        render={() => <h1 className="flex items-center">Page Not Found</h1>}
      />
    </Switch>
  )
}

export default App
