import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Form, Field, Formik } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import _ from 'lodash'
//Query/Mutation
import { DASHBOARD_FEED_QUERY } from '../../queries/queries'
import { DASHBOARD_UPDATE_BIO } from '../../mutations/mutations'
//Components
import LoadingMessage from '../../utils/LoadingMessage'
import ErrorMessage from '../../utils/ErrorMessage'

const GEN = ['MALE', 'FEMALE']
const ETH = ['BLACK', 'WHITE', 'NATIVE_AMERICAN', 'HISPANIC', 'ASIAN', 'OTHER']
const IND = [
  'BUSINESS_OPERATIONS',
  'CONSULTING',
  'DESIGN',
  'EDUCATION',
  'ENGINEERING',
  'ENTERTAINMENT',
  'ENTREPRENEURSHIP',
  'FASHION',
  'FINANCE',
  'FINE_ARTS',
  'FOOD',
  'GOVERNMENT',
  'HOSPITALITY',
  'LAW',
  'MARKETING',
  'MEDICINE',
  'PHARMA_MED_DEVICE',
  'NON_PROFIT',
  'SPORTS',
  'SOCIAL_MEDIA',
  'TECH',
]

const UserProfile = ({ profileId }) => {
  const { loading, error, data, refetch } = useQuery(DASHBOARD_FEED_QUERY, {
    fetchPolicy: 'network-only',
  })
  const [updateBio] = useMutation(DASHBOARD_UPDATE_BIO)

  useEffect(() => {
    refetch()
  })

  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />

  const userinfo = data.dashboardFeed.find((user) => user.id === profileId)

  return (
    <div>
      <Formik
        initialValues={{
          industry: userinfo.bio.industry,
          gender: userinfo.bio.gender,
          title: userinfo.bio.title,
          location: userinfo.bio.location,
          experiance: userinfo.bio.experiance,
          body: userinfo.bio.body,
          ethnicity: userinfo.bio.ethnicity,
        }}
        onSubmit={(values, { setSubtting }) => {
          updateBio({
            variables: {
              id: userinfo.bio.id,
              industry: values.industry,
              gender: values.gender,
              title: _.startCase(_.camelCase(`${values.title}`)),
              location: _.startCase(_.camelCase(`${values.location}`)),
              ethnicity: values.ethnicity,
              experiance: parseInt(values.experiance),
              body: values.body,
            },
          }).catch((e) => {
            return null
          })
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                City:
              </label>
              <Field
                className="shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                value={values.location}
                type="text"
                name="location"
                placeholder={userinfo.bio.location}
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Years of Experiance:
              </label>
              <Field
                className="w-16 shadow appearance-none border rounded py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                value={values.experiance}
                type="text"
                name="experiance"
                placeholder={userinfo.bio.experiance}
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Title:
              </label>
              <Field
                className="shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                value={values.title}
                type="text"
                name="title"
                placeholder={userinfo.bio.title}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Ethnicity:
              </label>
              <Field
                className="shadow border inset-y-0 right-0 flex items-center py-2 px-2 text-gray-700"
                as="select"
                name="ethnicity"
                value={values.ethnicity}
              >
                {ETH.map((i, index) => (
                  <option key={index} value={i}>
                    {_.startCase(_.camelCase(i))}
                  </option>
                ))}
              </Field>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Industry:
              </label>
              <Field
                className="shadow border inset-y-0 right-0 flex items-center py-2 px-2 text-gray-700"
                as="select"
                name="industry"
              >
                {IND.map((i, index) => (
                  <option key={index} value={i}>
                    {_.startCase(_.camelCase(i))}
                  </option>
                ))}
              </Field>
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Gender:
              </label>
              <Field
                className="shadow border inset-y-0 right-0 flex items-center py-2 px-2 text-gray-700"
                as="select"
                name="gender"
              >
                {GEN.map((i, index) => (
                  <option key={index} value={i}>
                    {_.startCase(_.camelCase(i))}
                  </option>
                ))}
              </Field>
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Bio:
              </label>
              <Field
                as="textarea"
                rows="10"
                cols="30"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                value={values.body}
                type="textarea"
                name="body"
                placeholder={userinfo.bio.body}
              />
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UserProfile
