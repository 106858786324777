import React from 'react'
import dateformat from 'dateformat'
import { Link } from 'react-router-dom'
import defaultAvatar from '../../assets/img/default-avatar.png'
import _ from 'lodash'

const UserTableData = ({ userInfo, onClick }) => {
  const ConvertDate = () => {
    let dt = new Date(userInfo.createdAt)
    let dtConverted = dateformat(dt, 'dddd, mmmm dS, yyyy')

    return <span>{dtConverted}</span>
  }

  return (
    <React.Fragment>
      <tr>
        <td className="px-10 py-5 border-b border-gray-200 bg-white text-sm">
          <div className="flex items-center">
            <div className="flex-shrink-0 w-10 h-10">
              <img
                className="w-full h-full rounded-full object-cover"
                src={
                  !_.startsWith(userInfo.avatar, 'https')
                    ? defaultAvatar
                    : userInfo.avatar
                }
                alt="avatar"
              />
            </div>
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">
                {userInfo.firstName} {userInfo.lastName}
              </p>
            </div>
          </div>
        </td>
        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          <p className="text-gray-900 whitespace-no-wrap">{userInfo.email}</p>
        </td>
        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          <p className="text-gray-900 whitespace-no-wrap">{userInfo.role}</p>
        </td>
        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          <p className="text-gray-900 whitespace-no-wrap">{<ConvertDate />}</p>
        </td>
        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          <span
            className={`relative inline-block px-3 py-1 font-semibold ${
              userInfo.isLoggedIn ? 'text-green-900' : 'text-red-900'
            } leading-tight`}
          >
            <span
              aria-hidden
              className={`absolute inset-0 opacity-50 rounded-full ${
                userInfo.isLoggedIn ? 'bg-green-200' : 'bg-red-200'
              }`}
            ></span>
            <span className="relative">
              {userInfo.isLoggedIn ? 'Online' : 'Offline'}
            </span>
          </span>
        </td>
        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          <Link
            to={(location) => `${location.pathname}${userInfo.id}`}
            className="bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-bold py-2 px-4 rounded"
          >
            Edit
          </Link>
        </td>
      </tr>
    </React.Fragment>
  )
}

export default UserTableData
