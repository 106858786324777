import React from 'react'
import { BrowserRouter as Router, withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
//Query
import { DASHBOARD_FEED_QUERY } from '../../queries/queries'
import LoadingMessage from '../../utils/LoadingMessage'
import ErrorMessage from '../../utils/ErrorMessage'

import UserProfileTabsRender from './UserProfileTabs'

const UserDetailsPage = ({ match }) => {
  const { loading, error, data } = useQuery(DASHBOARD_FEED_QUERY)

  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />

  const userinfo = data.dashboardFeed.find(
    (user) => user.id === match.params.userId
  )
  return (
    <Router basename={`/dashboard/${match.params.userId}`} forceRefresh={false}>
      <div className="md:flex bg-white rounded-lg p-6">
        <img
          className="h-16 w-16 md:h-24 md:w-24 rounded-full mx-auto md:mx-0 md:mr-6 object-cover"
          src={userinfo.avatar}
          alt=""
        />
        <div className="text-center md:text-left">
          <h2 className="text-lg">
            {userinfo.firstName} {userinfo.lastName}
          </h2>
          <div className="text-purple-500">{userinfo.bio.title}</div>

          <div className="text-gray-600">{userinfo.email}</div>
        </div>
      </div>
      <UserProfileTabsRender data={match} />
    </Router>
  )
}

export default withRouter(UserDetailsPage)
