import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Form, Field, Formik } from 'formik'
import * as yup from 'yup'
import { LOGIN_USER } from '../mutations/mutations'
import { useHistory } from 'react-router-dom'

const Login = (props) => {
  const [loginUser] = useMutation(LOGIN_USER)
  const history = useHistory()

  return (
    <div className="container mx-auto h-full flex justify-center items-center mt-64">
      <div className="w-full max-w-xs">
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values, { setSubmitting }) => {
            loginUser({
              variables: {
                email: values.email,
                password: values.password,
                isLoggedIn: true,
              },
            })
              .then((data) => {
                const token = data.data.login.token
                localStorage.setItem('token', token)
                setSubmitting(false)

                history.push('/dashboard')
              })
              .catch((e) => {
                alert('Invalild Username/Password')
                props.history.push('/error')
                return null
              })
          }}
          validationSchema={yup.object().shape({
            email: yup.string().email().required('Email required!'),
            password: yup
              .string()
              .min(9, 'Password must be longer than 9 characters')
              .required(),
          })}
          render={({ submitForm, isSubmitting, values, touched, errors }) => (
            <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                {touched.email && errors.email && (
                  <p className="text-red-500">{errors.email}</p>
                )}
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Email
                </label>
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={values.email}
                  type="email"
                  name="email"
                  placeholder="example@example.com"
                />
              </div>
              <div className="mb-6">
                {touched.password && errors.password && (
                  <p className="text-red-600">{errors.password}</p>
                )}
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Password
                </label>
                <Field
                  className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  value={values.password}
                  type="password"
                  name="password"
                  placeholder="******************"
                />
                <p className="text-red-500 text-xs italic">
                  Please choose a password.
                </p>
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Sign In
                </button>
                {/* <a
                  className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                  href="http://#"
                >
                  Forgot Password?
                </a> */}
              </div>
            </Form>
          )}
        />

        <Formik />
        <p className="text-center text-gray-500 text-xs">
          &copy;2020 Nuggt. All rights reserved.
        </p>
      </div>
    </div>
  )
}

export default Login
