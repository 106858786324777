import React from 'react'
import jwt from 'jsonwebtoken'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem('token')

  if (!token) return <Redirect push to="/" />

  const decoded = jwt.verify(token, process.env.REACT_APP_JWT_SECRET)

  return (
    <Route
      {...rest}
      render={(props) =>
        decoded.role === 'ADMIN' && token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
