import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Apollo Client
import { useQuery } from '@apollo/react-hooks'
import {
  DASHBOARD_FEED_QUERY,
  GET_CURRENT_USER_QUERY,
} from '../queries/queries'
// Logo
import logo from '../assets/img/logo_dark.png'
// Components
import UserTable from '../components/User/UserTable'
import DashboardHeader from '../components/DashboardHeader'
import UserDetailsPage from '../components/User/UserDetailsPage'

const Dashboard = ({ history }) => {
  const { loading: allUsersLoading, data: allUserData } = useQuery(
    DASHBOARD_FEED_QUERY
  )
  const { data: useQueryData } = useQuery(GET_CURRENT_USER_QUERY)

  // const [currentPage, setCurrentPage] = useState(1)
  const logOut = () => {
    localStorage.removeItem('token')
    history.push('/')
  }

  const dashboardRoutes = [
    {
      path: '/',
      exact: true,
      main: () => (
        <UserTable queryData={allUserData} isLoading={allUsersLoading} />
      ),
    },
    {
      path: '/:userId',
      exact: true,
      main: () => <UserDetailsPage />,
    },
  ]
  return (
    <Router basename="dashboard">
      <div className="h-screen flex">
        {/* sidebar navigation and logo */}
        <div className="w-64 px-8 py-4 bg-gray-100 border-r">
          <img className="h-6" src={logo} alt="Nuggt Logo" />
          <nav className="mt-8">
            <h3 className="text-xs font-semibold text-gray-600 tracking-wide">
              User Management
            </h3>
            <div className="mt-2 -mx-3">
              <NavLink
                to="/"
                exact
                activeClassName="bg-gray-200"
                className="flex items-center justify-between px-3 py-2 text-sm font-medium text-gray-900 rounded-lg"
              >
                <span>All Users</span>
                <span className="text-xs font-semibold text-gray-700">
                  {allUserData && allUserData.dashboardFeed.length}
                </span>
              </NavLink>
            </div>
          </nav>
        </div>

        <div className="flex-1 min-w-0 bg-white">
          <div className="border-b-2 border-gray-200">
            <header className="px-6">
              <div className="flex justify-between items-center py-3 border-b border-gray-200">
                <div className="flex-1">
                  {/*  
                //     <div className="relative max-w-xs">
                //     <span className="absolute inset-y-0 left-0 p-3 flex items-center">
                //       <svg
                //         className="h-6 w-6 text-gray-600"
                //         viewBox="0 0 24 24"
                //         fill="none"
                //       >
                //         <path
                //           stroke="currentColor"
                //           strokeWidth="2"
                //           strokeLinecap="round"
                //           d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                //         />
                //       </svg>
                //     </span>
                //     <input
                //       className=" block w-full rounded-lg border border-gray-400 pl-10 pr-4 py-2 text-sm text-gray-900 placeholder-gray-600"
                //       placeholder="Search"
                //     />
                //   </div>
                */}
                </div>

                <div className="flex items-center">
                  <button>
                    <FontAwesomeIcon size="lg" icon={['far', 'bell']} />
                  </button>
                  <button className="ml-6">
                    <img
                      className="h-8 w-8 rounded-full object-cover"
                      src={useQueryData && useQueryData.me.avatar}
                      alt="Avatar"
                    />
                  </button>

                  <button
                    className="flex items-center ml-2 p-2 bg-gray-400 rounded-lg font-semibold"
                    onClick={logOut}
                  >
                    Logout
                  </button>
                </div>
              </div>

              {/* Dashboard header */}
              <DashboardHeader
                queryData={allUserData}
                isLoading={allUsersLoading}
              />
            </header>
          </div>
          <div>
            <main className="p-3 flex">
              <div className="p-3 bg-gray-100 rounded w-full">
                <Switch>
                  {dashboardRoutes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      children={<route.main />}
                    />
                  ))}
                </Switch>
              </div>
            </main>
          </div>
        </div>
      </div>
    </Router>
  )
}

export default Dashboard
